<template>
  <div class="card card-custom card-transparent">
    <div v-if="submitStatus==='ERROR'" >
          <b-alert  show variant="danger"><i class="fas fa-exclamation-circle text-white mr-3"></i>

You must share your location before creating an address</b-alert>
        </div>
    
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Address</div>
                  <div class="wizard-desc">Create New Address</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" id="confirmation" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label" >
                  <div class="wizard-title">Confirmation</div>
                  <div class="wizard-desc">Confirmation Add</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type=""></div>
            <div class="wizard-step" data-wizard-type=""></div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div class="col-xl-12">
                      <!--begin::Nav Panel Widget 2-->
                      <div
                        class="card card-custom card-stretch gutter-b"
                        v-if="!clicked"
                      >
                        <!--begin::Body-->
                        <div class="card-body">
                          <!--begin::Wrapper-->
                          <div
                            class="d-flex justify-content-between flex-column pt-4 h-100"
                          >
                            <!--begin::Container-->
                            <div class="pb-5">
                              <!--begin::Header-->
                              <div class="d-flex flex-column flex-center">
                                <!--begin::Symbol-->
                                <div
                                  class="symbol symbol-120 symbol-circle symbol-success overflow-hidden"
                                >
                                  <span class="symbol-label" 
                                  :style="{
                  backgroundImage: `url(${profileImage})`,
                }"
                                  >
                                    
                                  </span>
                                </div>
                                <!--end::Symbol-->
                                <!--begin::Username-->
                                <a
                                  href="#"
                                  class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1"
                                  >{{ customer.name }}</a
                                >
                                <!--end::Username-->
                                <!--begin::Info-->
                                
                                <!--end::Info-->
                              </div>
                              <!--end::Header-->
                              <!--begin::Body-->
                              <div class="pt-1">
                                <!--begin::Text-->
                               
                                <!--end::Text-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center pb-9" v-for="address in customer.addresses" :key="address.id">
                                  <!--begin::Symbol-->
                                  <div
                                    class="symbol symbol-45 symbol-light mr-4"
                                  >
                                    <span class="symbol-label">
                                      <span
                                        class="svg-icon svg-icon-2x svg-icon-dark-50"
                                      >
                                        <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->

                                        <!--end::Svg Icon-->
                                        <i
                                          class="fas fa-map-marked-alt font-size-h1"
                                        ></i>
                                      </span>
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1">
                                    <a
                                      href="#"
                                      class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
                                      >{{ address.area }}</a
                                    >
                                    {{ address.street_name }}, Building {{ address.building }}, Floor {{ address.floor }}
                                  </div>
                                  <!--end::Text-->
                                  <!--begin::label-->
                                  <button
                                    class="font-weight-bolder btn btn-success label-inline align-middle"
                                     @click.prevent="showSelectModal(address)" data-wizard-type="action-next"
                                    >Select</button
                                  >
                                  <!--<i class="fas fa-pen text-success ml-3"></i>-->
                                    <button class="font-weight-bolder label label-xl label-light-danger btn ml-4 label-inline px-3 py-5 min-w-45px">
                                      <i class="fas fa-trash text-danger ml-3" @click.prevent="showModal(address)"></i>
                                    </button>
                                   
                                  
                                  

                             <!--      <span
                                    class="font-weight-bolder label label-xl label-light-danger btn ml-4 label-inline px-3 py-5 min-w-45px"
                                    >Edit</span
                                  > -->
                                  <!--end::label-->
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->

                                <!--end::Item-->
                                <!--begin::Item-->

                                <!--end::Item-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--eng::Container-->
                            <!--begin::Footer-->
                            <div
                              class="d-flex flex-center"
                              id="kt_sticky_toolbar_chat_toggler_1"
                              title=""
                              data-placement="right"
                              data-original-title="Chat Example"
                            >
                              <button
                                class="btn btn-primary font-weight-bolder font-size-sm py-3 px-14"
                                @click="setClick"
                                style="width: 100%"
                              >
                                Create Address
                              </button>
                            </div>
                            <!--end::Footer-->
                          </div>
                          <!--end::Wrapper-->
                        </div>
                        <!--end::Body-->
                      </div>
                      <!--end::Nav Panel Widget 2-->
                    </div>
                    <div v-if="clicked">
                      <div class="form-group" >
                        <label label class="form__label">Street Name</label>
                        <b-form-input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="Sname"
                          placeholder="Street Name"
                          v-model="address.street_name"
                          
                        />
                       

                      </div>
                      <div class="form-group" >
                        <label label class="form__label">Area</label>
                        <b-form-input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          name="area"
                          placeholder="Area"
                          v-model="address.area"
                          
                          
                        />
                        
                      </div>
                      
                      
                      <div class="row">
                        <div class="col-xl-6">
                          <div class="form-group" >
                            <label>Building</label>
                            <b-form-input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="building"
                              placeholder="Building"
                              v-model="address.building"
                              
                              
                            />
                            
                          </div>
                         
                        </div>
                        <div class="col-xl-6">
                          <div class="form-group" >
                            <label>Floor</label>
                            <b-form-input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="floor"
                              placeholder="Floor"
                              v-model="address.floor"
                              
                              
                            />
                            
                          </div>
                          
                        </div>
                      </div>
                      <div class="form-group" >
                        <label for="" class="mb-4">Location Type</label>
                        <b-form-radio-group class="ml-30">
                          <b-form-radio
                            v-model="address.type"
                            value="Home"
                            ><span>Home</span></b-form-radio
                          >
                          <b-form-radio
                            v-model="address.type"
                            value="Work"
                            ><span >Work</span></b-form-radio
                            
                          >
                        </b-form-radio-group>
                        
                      </div>

                      <div class="row">
                        <!--<div class="col-xl-6">
                          <div class="form-group" :class="{ 'form-group--error': $v.address.long.$error }">
                            <label>Longtiude</label>
                            <b-form-input
                              type="number"
                              class="form-control form-control-solid form-control-lg"
                              disabled
                              v-model="address.long"
                              :class="{ 'form-input--error': $v.address.long.$error }"
                              :state="$v.address.long.$error===true?false:null"
                            />
                            <p class="typo__p" v-if="submitStatus === 'ERROR'">This field is mandatory.</p>
                          </div>
                        </div>
                        <div class="col-xl-6">
                          <div class="form-group" :class="{ 'form-group--error': $v.address.lat.$error }">
                            <label>Lattitude </label>
                            <input
                              type="number"
                              class="form-control form-control-solid form-control-lg"
                              v-model="address.lat"
                              disabled
                              :class="{ 'form-input--error': $v.address.lat.$error }"
                              :state="$v.address.lat.$error===true?false:null"
                            />
                            <p class="typo__p" v-if="submitStatus === 'ERROR'">This field is mandatory.</p>
                          </div>
                        </div>-->
                       <div class="alert alert-custom alert-white alert-shadow fade show col-lg-12" role="alert">
                          <label class="col-lg-11 col-9 col-form-label mt-5" >{{pickerText}}</label>
                            <div class="col-lg-1 col-3 mt-5"  >
                            <span class="switch switch-icon">
                              <label>
                              <input type="checkbox" :value="picker" @change="switchPickerChange" checked="checked" name="select"/>
                              <span></span>
                              </label>
                            </span>
                          </div>
                    </div>
                          
                        
                       
                        
                    <div class="alert alert-custom alert-light-primary fade show mb-5 col-lg-12 mt-5" role="alert" v-if="picker==='Manual'">
                        <div class="alert-icon"><i class="fas fa-location-arrow"></i></div>
                        <div class="alert-text">Please drag the picker to your location or click on on it using the mouse</div>
                        <div class="alert-close">
                            
                        </div>
                    </div>
                        <div class="col-lg-12 mt-5" @click="geolocation" v-if="picker==='Auto'">
                          <span
                            class="btn btn-danger btn-lg mt-4 mb-8"
                            style="width: 100%"
                          >
                            <i class="fas fa-map-marker"></i> Share Map Location
                          </span>
                        </div>

                        <div class="col-xl-12" @click="geolocation" v-if="picker=='Auto'">
                          <div class="location mb-5">
                        <template>
                          <GmapMap
                            style="width: 100%; height: 60vh; margin: auto"
                            :center="{
                              lat: address.lat,
                              lng: address.long,
                            }"
                            :zoom="17"
                          >
                            <GmapMarker
                              label="★"
                              :position="{
                                lat: this.address.lat,
                                lng: this.address.long,
                              }"
                            />

                          </GmapMap>
                        </template>
                      </div>
                        </div>

                        <div class="col-xl-12"  v-if="picker=='Manual'">
                          <div class="location mb-5">
                        <template>
                          <GmapMap
                            style="width: 100%; height: 60vh; margin: auto"
                            :center="{
                              lat: 31.963158,
                              lng: 35.930359,
                            }"
                            :zoom="7"
                            @click="handleMapClick"
                          >
                            
                          
                            <GmapMarker
                              
                              :clickable="true"
                              :draggable="true"
                              :icon="{
                               url:require('../../../../public/Pin.png'),
                               scaledSize: {width: 70, height: 70},
                               }
                                "
                              
                              :position="{
                                lat: this.address.lat,
                                lng: this.address.long,
                              }"
                              @drag="handleMarkerDrag"
                            />

                          </GmapMap>
                        </template>
                      </div>
                        </div>
                        
                        <div class="col-xl-6" v-if="picker==='Manual'">
                          <div class="form-group" >
                            <label>Latitude</label>
                            <b-form-input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="lat"
                              placeholder="Latitude"
                              v-model="address.lat"
                              disabled
                              
                              
                            />
                            
                          </div>
                         
                        </div>

                        <div class="col-xl-6" v-if="picker==='Manual'">
                          <div class="form-group" >
                            <label>Longitude</label>
                            <b-form-input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="long"
                              placeholder="Longitude"
                             v-model="address.long"
                              disabled
                              
                            />
                            
                          </div>
                         
                        </div>

                        <div class="col-lg-12" >
                          <button
                            @click.prevent="saveAddress"
                            class="btn btn-success btn-lg mt-8"
                            style="width: 100%"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      
                      
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="col-xl-12">
                      <!--begin::Mixed Widget 14-->
                      <div class="card card-custom gutter-b card-stretch">
                        <!--begin::Header-->
                        <div class="card-header border-0 pt-5">
                          <h3 class="card-title font-weight-bolder">
                            Total Delivery Fees
                          </h3>
                          
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body d-flex flex-column">
                          <div class="flex-grow-1" style="margin:auto;">
                            <div
                              id="kt_mixed_widget_14_chart"
                              style="height: 200px; min-height: 278.7px ; "
                            >
                              <div
                                id="apexchartsnk7vhztpf"
                                class="apexcharts-canvas apexchartsnk7vhztpf apexcharts-theme-light"
                                style="width: 100%; height: 178.7px" 
                              >
                                <svg
                                  id="SvgjsSvg2253"
                                  width="100%"
                                  height="278.7"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  xmlns:svgjs="http://svgjs.com/svgjs"
                                  class="apexcharts-svg"
                                  xmlns:data="ApexChartsNS"
                                 
                                  style="background: transparent;"
                                >
                                  <g
                                    id="SvgjsG2255"
                                    class="apexcharts-inner apexcharts-graphical"
                                    
                                  >
                                   
                                    <g
                                      id="SvgjsG2259"
                                      class="apexcharts-radialbar"
                                    >
                                      <g id="SvgjsG2260">
                                        <g
                                          id="SvgjsG2261"
                                          class="apexcharts-tracks"
                                        >
                                          <g
                                            id="SvgjsG2262"
                                            class="apexcharts-radialbar-track apexcharts-track"
                                            rel="1"
                                          >
                                            <path
                                              id="apexcharts-radialbarTrack-0"
                                              d="M 88 26.60792682926828 A 70.39207317073172 70.39207317073172 0 1 1 87.98928506193984 26.607927764323023"
                                              fill="none"
                                              fill-opacity="1"
                                              stroke="rgba(27,197,189,0.85)"
                                              stroke-opacity="1"
                                              stroke-linecap="round"
                                              stroke-width="8.97439024390244"
                                              stroke-dasharray="0"
                                              class="apexcharts-radialbar-area apexcharts-radialbar-slice-0"
                                              data:angle="266"
                                              data:value="74"
                                              data:pathOrig="M 88 26.60792682926828 A 61.39207317073172 61.39207317073172 0 1 1 87.98928506193984 26.607927764323023"
                                            ></path>
                                          </g>
                                        </g>
                                        <g id="SvgjsG2264">
                                         
                                          
                                          <g
                                            id="SvgjsG2266"
                                            class="apexcharts-datalabels-group"
                                            transform="translate(0, 0) scale(1)"
                                            style="opacity: 1"
                                          >
                                            <text
                                              id="SvgjsText2267"
                                              font-family="Helvetica, Arial, sans-serif"
                                              x="88"
                                              y="85"
                                              text-anchor="middle"
                                              dominant-baseline="auto"
                                              font-size="30px"
                                              font-weight="700"
                                              fill="#5e6278"
                                              class="apexcharts-text apexcharts-datalabel-value"
                                              style="
                                                font-family: Helvetica, Arial,
                                                  sans-serif;
                                              "
                                            >
                                              {{ fees }} 
                                            </text>

                                            <text
                                              id="SvgjsText2267"
                                              font-family="Helvetica, Arial, sans-serif"
                                              x="88"
                                              y="130"
                                              text-anchor="middle"
                                              dominant-baseline="auto"
                                              font-size="30px"
                                              font-weight="700"
                                              fill="#5e6278"
                                              class="apexcharts-text apexcharts-datalabel-value"
                                              style="
                                                font-family: Helvetica, Arial,
                                                  sans-serif;
                                              "
                                            >
                                              JOD 
                                            </text>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <line
                                      id="SvgjsLine2270"
                                      x1="0"
                                      y1="0"
                                      x2="176"
                                      y2="0"
                                      stroke="#b6b6b6"
                                      stroke-dasharray="0"
                                      stroke-width="1"
                                      class="apexcharts-ycrosshairs"
                                    ></line>
                                    <line
                                      id="SvgjsLine2271"
                                      x1="0"
                                      y1="0"
                                      x2="176"
                                      y2="0"
                                      stroke-dasharray="0"
                                      stroke-width="0"
                                      class="apexcharts-ycrosshairs-hidden"
                                    ></line>
                                  </g>
                                  <g
                                    id="SvgjsG2256"
                                    class="apexcharts-annotations"
                                  ></g>
                                </svg>
                                <div class="apexcharts-legend"></div>
                              </div>
                            </div>
                            <div class="resize-triggers">
                              <div class="expand-trigger">
                                <div style="width: 317px; height: 201px"></div>
                              </div>
                              <div class="contract-trigger"></div>
                            </div>
                          </div>
                          <!--<div >
                            
                            <a
                              href="#"
                              @click.prevent="confirmOrder"
                              class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
                              data-wizard-type="action-submit"
                              >Confirm Order</a
                            >
                          </div>-->
                          <!--<div >
                            
                            <a
                              href="#"
                              
                              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 py-3 mt-5"
                              data-wizard-type="action-prev"
                              >Back</a
                            >
                          </div>-->
                        </div>
                        <!--end::Body-->
                      </div>
                      <!--end::Mixed Widget 14-->
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->

                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->

                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10" >
                    <div class="mr-2">
                      <!-- <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button> -->
                    </div>
                    <div>
                     <!--  <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button> -->
                      
                     <!--  <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button> -->
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      title='Delete Address'
      hide-footer
    >
      <p>
        Are you sure? If yes, all address information will be lost
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteAddress(tempadres)">Delete</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal-2"
      title='Address confirmation'
      hide-footer
    >
      <p>
        Are you sure delivery address information is correct?
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideSelectModal"
          >Cancel</b-button
        >
        <b-button variant="success" @click="selectAddress(tempSelectedAddress)">Agree</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { INITIALIZE_CSRF } from "@/core/services/store/auth.module";
import { required } from "vuelidate/lib/validators";
//import photo from '../../../../public/media/svg/clients/client.svg'


  //import image from '../../../assets/images/007-boy-2.svg'

export default {
  name: "SMS",

  data() {
    return {
      
      picker:'Auto',
      pickerText:'Auto Location Picker',
      selected: null,
      submitStatus:'',
      fees: 0.00,
      clicked: false,
      customer:'',
      order: {
      },
      address: {
        customer_id:'',
        phone: "",
        lat: null, 
        long: null,
      },
      markers: [],
      status: "",
      tempadres:'',
      tempSelectedAddress:''
      
    };
  },
  computed:{
    profileImage() {
      return (
        process.env.BASE_URL + "media/svg/clients/client.svg"
      );
    }
  },
  validations:{
    address:{
      long:{
        required
      },
      lat:{
        required
      }
    }

  },
  async beforeCreate() {
      let url = new URL(window.location.href);
      let order_id = url.searchParams.get("order_id");

      this.order = await this.$store.dispatch('orders/getOne', order_id);
      this.address.phone = this.order.phone
      console.log(this.order)
      if(this.order.isAddressConfirmed=='1'){
        this.$router.push({name: 'Confirmed', params: {id: this.order.hash_id} })
      }
      this.address.customer_id = this.order.customer_id
      this.customer = this.order.customer
      
  },
  async mounted() {
    this.$store.dispatch(INITIALIZE_CSRF)
    
    
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "SMS", route: "/sms" }]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      //console.log(wizard.stop())
      //console.log(wizard.goNext())

      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    handleMapClick(e){
      this.address.lat = e.latLng.lat()
      this.address.long = e.latLng.lng()
    },
    handleMarkerDrag(e){
      
      this.address.lat = e.latLng.lat()
      this.address.long = e.latLng.lng()
    },
    switchPickerChange(){
      
      if(this.picker === 'Auto'){
        this.picker = 'Manual'
        this.pickerText = 'Manual Location Picker'
        this.address.lat=31.963158
        this.address.long=35.930359
      }else if(this.picker === 'Manual'){
        this.picker = 'Auto'
        this.pickerText = 'Auto Location Picker'
      }

      console.log(this.picker)
    },
    showModal(addres) {
      this.$refs["my-modal"].show();
      this.tempadres = addres
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showSelectModal(addres) {
      this.$refs["my-modal-2"].show();
      this.tempSelectedAddress = addres
      
    },
    hideSelectModal() {
      this.$refs["my-modal-2"].hide();
    },
    async deleteAddress(ad){
      //console.log(ad)
      await this.$store.dispatch('orders/deleteAddress', ad);
      window.location.reload()
    },
    async selectAddress(address) {
      this.selected = address;
     
     //console.log(this.order)

      this.fees = await this.$store.dispatch('orders/fetchFees', {
        order_id: this.order.id, 
        longitude: this.selected.long,
        latitude: this.selected.lat,
      });

      //this.fees=2.52

      let confirmation = document.getElementById("confirmation");
      setTimeout(function() {
        confirmation.click();
      }, 500);
      
      this.hideSelectModal()
      Swal.fire({
          title: "",
          text: "The order has been confirmed successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
      });
      this.confirmOrder()
    },
    async confirmOrder() {
      let address = this.selected;

      await this.$store.dispatch('orders/editTask', {
        order_id: this.order.id, 
        longitude: address.long,
        latitude: address.lat,
        fees: parseFloat(this.fees),
      });

       

      // window.location.replace(this.order.tracking_url);
      

      //this.$router.push({name: 'Confirmed', params: {id: this.order.hash_id} })

    },
    async saveAddress () {
      
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      }else{
        await this.$store.dispatch('orders/createAddress', this.address);

      Swal.fire({
          title: "",
          text: "The new address has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
      });

      window.location.reload()
      }

        
        
     
        
     
      
    },
    submit: function (e) {
      e.preventDefault();
      /*if(this.clicked==true){

        this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        Swal.fire({
          title: "",
          text: "The application has been successfully submitted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
        
      }
      else{
        this.submitStatus = "OK";
        Swal.fire({
          title: "",
          text: "The application has been successfully submitted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }*/
      
    },
    setClick: function () {
      this.clicked = true;
    },
    setPlace: function (place) {
      this.place = place;
    },
    geolocation: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.address.lat = position.coords.latitude;
        this.address.long = position.coords.longitude;
      });
    },
  },
 
};
</script>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
.typo__p {
  color: rgba(255, 0, 0, 0.8);
}

#SvgjsSvg2253{
 width: 200px;
}

.symbol-label{
  border-radius: 200px;
  background-size: 170%;
}



#kt_mixed_widget_14_chart{
display: flex;
display: grid;
margin: auto;
text-align: center;
width: 300px;
}

.card.card-custom > .card-body{
      padding: 2rem 1rem;
}

@media only screen and (max-width: 400px) {
  #SvgjsSvg2253{
 width: 190px;
}
.col-xl-12{
      position: relative;
    width: 100%;
     padding-right: 0;
     padding-left: 0;
}
}

</style>